import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import TitleBanner from '../components/TitleBanner'
import Contact from '../components/Contact'

// Placeholders
import slide1Image from '../../static/assets/phone.jpg'



const ContactUsPage = props => (


  <Layout>
    <Helmet
      title={'Contact Metro Communications'}
      meta={[
        {name: `description`, content: `Contact Metro Communications, leading provider of “Cloud first” IT and telecommunications solutions for business.`},
        {name: `keywords`, content: ``},
      ]}
    />
    <TitleBanner
      image={slide1Image}
      title={`Contact Us`}
    />
    <Contact page={`Contact Us`} />

  </Layout>

)

export default ContactUsPage;